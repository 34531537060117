





























































import {defineComponent, SetupContext} from "@vue/composition-api";
import {HomeCard} from "@/pages/home/impl/HomeCard";
import useHomeCard from "@/pages/home/impl/useHomeCard";

export default defineComponent({
    name: "HomeCardList",
    props: {
        card: {
            type: HomeCard,
            required: true
        }
    },
    setup(props, context: SetupContext) {
        return {
            ...useHomeCard(props, context),
        }
    }
})
