import { JsonObject, JsonProperty } from "@axional/ax-object-mapper";
import { HomeBanner }               from "@/pages/home/impl/HomeBanner";
import {HomeSection}                from "@/pages/home/impl/HomeSection";

/**
 * Wrapper class for Home Page
 *
 * Example: {
 *      home_banners: []
 *      home_name: "BBC"
 *      home_sections: [{section_id: 1, section_order: 2, section_title: "", …},…]
 * }
 *
 */
@JsonObject("HomePage")
export default class HomePage {
    @JsonProperty("home_name", String, true)
    m_home_name: string = "";

    @JsonProperty("home_banners", [HomeBanner], true)
    m_home_banners: HomeBanner[] = [];

    @JsonProperty("home_sections", [HomeSection], true)
    m_home_sections: HomeSection[] = [];

    /**
     * Check if the home has banners or not
     */
    public hasHomeBanners(): boolean
    {
        return this.m_home_banners.length > 0;
    }

    /**
     * Get home banners
     */
    public getHomeBanners(): HomeBanner[]
    {
        return this.m_home_banners;
    }

    /**
     * Returns if the home has home sections or not
     */
    public hasHomeSections(): boolean
    {
        return this.m_home_sections.length > 0;
    }

    /**
     * Get the array of Home Sections
     *
     * Example:
     * HomeSection: {
     *      box_cards: [{box_id: 2, box_order: 2, box_cols: 6,…}, {box_id: 3, box_order: 4, box_cols: 3,…},…]
     *      has_image: 0
     *      section_color: null
     *      section_desc: null
     *      section_id: 1
     *      section_order: 2
     *      section_title: "Welcome to BBC.com"
     * }
     *
     * The home sections are sorted in client side
     * because in the server side the select returns a multiset
     *
     */
    public getHomeSections(): HomeSection[]
    {
        // Source data is sorted data through sort ().
        // because array.sort() changes the array itself, vue triggers re-render again
        // ending up in an infinite update loop.
        // To solve this, we need to shallow copy the array into a new array.
        const sections = [...this.m_home_sections];
        return sections.sort(
            (a,b) => a.getSectionOrder() - b.getSectionOrder()
        );
    }
}
