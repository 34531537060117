



















import {defineComponent} from "@vue/composition-api";

export default defineComponent({
    name: "HomeBanner",
    props: {
        banners: {
            type: Array,
            required: true
        }
    }
})
