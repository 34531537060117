import { SetupContext }   from "@vue/composition-api";

/**
 * Module that contains the shared home card functionalities
 *
 * @param props
 * @param context
 */
export default function useHomeCard(
    props: any,
    context: SetupContext
) {
    /**
     *
     */
    const noImage = require('@/assets/images/no_image.png');

    /**
     *
     */
    function goToNews(): void {
        if (!props.card.getCardUrl() && props.card.getNewsId()) {
            context.root.$router.push({
                name: "news",
                params: {
                    news_id: String(props.card.getNewsId())
                }
            });
        }
    }

    return {
        goToNews,
        noImage
    }
}
