import { JsonObject, JsonProperty }   from "@axional/ax-object-mapper";
import { HomeCardItem }               from "@/pages/home/impl/HomeCardItem";
import { API_PREFIX_MAIN }            from "@/constants";

/**
 * Enum of the available card types
 */
export enum CardType {
    Banner,
    Card,
    List
}

@JsonObject("HomeCard")
export class HomeCard {
    /**
     * The card id
     * @private
     */
    @JsonProperty("card_id", Number)
    private m_card_id: number = 0;

    /**
     * The card name
     * @private
     */
    @JsonProperty("card_name", String)
    private m_card_name: string = "";

    /**
     * Otional card text
     * @private
     */
    @JsonProperty("card_text", String, true)
    private m_card_text: string | null = null;

    /**
     * An optional mdi icon fr the card
     * @private
     */
    @JsonProperty("card_icon", String, true)
    private m_card_icon: string | null = null;

    /**
     * The card type
     *
     * Values (
     *      Banner = 0,
     *      Card = 1,
     *      List = 2
     * )
     *
     * @private
     */
    @JsonProperty("card_type", Number)
    private m_card_type: number = 0;

    /**
     * The card order used to sort
     * @private
     */
    @JsonProperty("card_order", Number)
    private m_card_order: number = 0;

    /**
     * The linked news_id to these card
     * @private
     */
    @JsonProperty("news_id", Number, true)
    private m_news_id: number | null = null;

    /**
     *
     * @private
     */
    @JsonProperty("card_bg_color", String, true)
    private m_card_bg_color: string | null = null;

    /**
     *
     * @private
     */
    @JsonProperty("card_fg_color", String, true)
    private m_card_fg_color: string | null = null;

    /**
     *
     * @private
     */
    @JsonProperty("card_items", [HomeCardItem] , true)
    private m_card_items: HomeCardItem[] = []

    /**
     * The external url for redirect the user when click
     * @private
     */
    @JsonProperty("link_url", String, true)
    private m_card_url: string | null = null;

    /**
     * Virtual column for know if the card have image
     * @private
     */
    @JsonProperty("has_image", Number)
    private m_has_image: number = 0;

    /**
     * Get the card title
     */
    public getCardTitle(): string | null
    {
        return this.m_card_name;
    }

    /**
     * Check if the card has description
     */
    public hasCardDescription(): boolean
    {
        return this.m_card_text != null;
    }

    /**
     * Get card description
     */
    public getCardDescription(): string | null
    {
        return this.m_card_text;
    }

    /**
     * Check if the card has an icon
     */
    public hasCardIcon(): boolean
    {
        return this.m_card_icon != null;
    }

    /**
     * Get the mdi card icon
     */
    public getCardIcon(): string | null
    {
        return this.m_card_icon;
    }

    /**
     * The card type
     * Values (
     *      Banner = 0,
     *      Card = 1,
     *      List = 2
     * )
     */
    public getCardType(): CardType
    {
        return this.m_card_type;
    }

    /**
     * The order of the card for sort
     */
    public getCardOrder(): number
    {
        return this.m_card_order;
    }

    /**
     * Get the background color of the card
     */
    public getCardBgColor(): string | null
    {
        return this.m_card_bg_color;
    }

    /**
     * Get the foreground color of the card
     */
    public getCardFgColor(): string | null
    {
        return this.m_card_fg_color;
    }

    /**
     * Check if the card contains list items
     */
    public hasCardItems(): boolean
    {
        return this.m_card_items.length > 0;
    }

    /**
     * Get a list of items to print the card as a list
     */
    public getCardItems(): HomeCardItem[]
    {
        return this.m_card_items;
    }

    /**
     * The url of an external webpage
     */
    public getCardUrl(): string | null
    {
        return this.m_card_url;
    }

    /**
     * THe news id that is linked to this card
     */
    public getNewsId(): number | null
    {
        return this.m_news_id;
    }

    /**
     * Check if the cards has an image
     */
    public hasCardImage(): boolean
    {
        return this.m_has_image === 1;
    }

    /**
     * Make a request for get the card image
     */
    public getCardImageUrl(): string | null
    {
        return `${API_PREFIX_MAIN}/home/card/${this.m_card_id}/image`;
    }
}
