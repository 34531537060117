import {JsonObject, JsonProperty} from "@axional/ax-object-mapper";
import {API_PREFIX_MAIN} from "@/constants";

@JsonObject("HomeBanner")
export class HomeBanner {
    @JsonProperty("image_id", Number)
    private readonly image_id: number = 0;

    @JsonProperty("image_pos", Number)
    private readonly image_pos: number = 0;

    @JsonProperty("link_url", String, true)
    private readonly link_url: string = "";

    public getImageSrc(): string
    {
        return `${API_PREFIX_MAIN}/home/banner/image/${this.image_id}`
    }
}
