











































































































import {
	SetupContext,
	defineComponent,
	inject,
	ref,
	Ref,
	onMounted
} from "@vue/composition-api";
import {ObjectMapper} from "@axional/ax-object-mapper";
import {axiosInstance} from "@/plugins/axios-instance";
import {API_PREFIX_MAIN} from "@/constants";
import HomeBanner from "@/pages/home/HomeBanner.vue";
import HomeCard from "@/pages/home/HomeCard.vue";
import HomePage from "@/pages/home/impl/HomePage";
import HomeCardBanner from "@/pages/home/HomeCardBanner.vue";
import {CardType} from "@/pages/home/impl/HomeCard";
import HomeCardList from "@/pages/home/HomeCardList.vue";
import {applicationService} from "@/services/ApplicationService";

export default defineComponent({
	name: "HomeView",
	components: {
		HomeBanner,
		HomeCard,
		HomeCardBanner,
		HomeCardList
	},
	setup(_, context: SetupContext) {
		/**
		 *
		 */
		const loading: Ref<boolean> = ref(false);
		/**
		 *
		 */
		const noHomeImage = require("@/assets/images/web_development.png");

		/**
		 * Holds the home page instance object.
		 */
		const home: Ref<HomePage | null> = ref(null);

		/**
		 * Get home UUID defined in application config.
		 */
		const home_uuid = applicationService.getAppHomeId();

		/**
		 * Fetch home data for the given application hom uuid.
		 */
		async function fetchHomeData(): Promise<void> {
			if (home_uuid) {
				loading.value = true;
				const response = await axiosInstance.get(`${API_PREFIX_MAIN}/home/${home_uuid}`);
				if (response.data) {
					home.value = new ObjectMapper().deserializeObject(response.data, HomePage);
				}
				loading.value = false;
			}
		}

		/**
		 * Fetch home data on component mounted and
		 * add home uuid in route url.
		 */
		onMounted(() => {
			fetchHomeData();
			if (!context.root.$route.query.uuid)
				context.root.$router.replace({query: {uuid: home_uuid}})
		});

		return {
			noHomeImage,
			home,
			CardType,
			loading
		}
	}
});
