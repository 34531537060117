import { JsonObject, JsonProperty } from "@axional/ax-object-mapper";
import { API_PREFIX_MAIN }          from "@/constants";
import { HomeBox }                  from "@/pages/home/impl/HomeBox";

/**
 *
 * HomeSection: {
 *      box_cards: [{box_id: 2, box_order: 2, box_cols: 6,…}, {box_id: 3, box_order: 4, box_cols: 3,…},…]
 *      has_image: 0
 *      section_color: null
 *      section_desc: null
 *      section_id: 1
 *      section_order: 2
 *      section_title: "Welcome to BBC.com"
 * }
 */
@JsonObject("HomeSection")
export class HomeSection {
    /**
     * The home section id
     * @private
     */
    @JsonProperty("section_id", Number)
    private m_section_id: number = 0;

    /**
     * The name of he section
     * Size 80 characters
     * @private
     */
    @JsonProperty("section_title", String,)
    private m_section_title: string = "";

    /**
     * Optional description for the section
     * Size 80 characters
     * @private
     */
    @JsonProperty("section_desc", String, true)
    private m_section_desc: string | null = null;

    /**
     * The number for order the sections
     * @private
     */
    @JsonProperty("section_order", Number)
    private m_section_order: number = 0;

    /**
     * Virtual column to check if the section has a background image
     * @private
     */
    @JsonProperty("has_image", Number)
    private m_has_image: number = 0;

    /**
     * The hex color of the section
     * @private
     */
    @JsonProperty("section_color", String, true)
    private m_section_color: string | null = null;

    /**
     * The hex color for foreground
     * @private
     */
    @JsonProperty("section_fg_color", String, true)
    private m_section_fg_color: string | null = null;

    /**
     * The array of boxes, it's used like a column in Vue
     * for wrap multiple cards in the same column
     *
     * Example: {
     *      box_id: 2
     *      box_cols: 6
     *      box_order: 2
     *      cards: [{card_id: 6, card_order: 2, card_name: "",…}]
     * }
     *
     * @private
     */
    @JsonProperty("box_cards", [HomeBox])
    private m_box_cards: HomeBox[] = []

    /**
     * Get the Section title
     */
    public getSectionTitle(): string
    {
        return this.m_section_title;
    }

    /**
     * Check if the section has description
     */
    public hasSectionDesc(): boolean
    {
        return this.m_section_desc != null;
    }

    /**
     *  Get the section description
     */
    public getSectionDesc(): string | null
    {
        return this.m_section_desc;
    }

    /**
     * Get the section order of make the sort in HomePage Class
     */
    public getSectionOrder(): number
    {
        return this.m_section_order;
    }

    /**
     * Get the sorted box by the column box_order
     *
     * The sections boxes are sorted in client side
     * because in the server side the select returns a multiset
     */
    public getBoxes(): HomeBox[]
    {
        // Source data is sorted data through sort ().
        // because array.sort() changes the array itself, vue triggers re-render again
        // ending up in an infinite update loop.
        // To solve this, we need to shallow copy the array into a new array.
        const boxes = [...this.m_box_cards];
        return boxes.sort((a,b) => a.getBoxOrder() - b.getBoxOrder());
    }

    /**
     * Get section color
     */
    public getSectionColor(): string | null
    {
        return this.m_section_color;
    }

    /**
     * Get section foreground color
     */
    public getSectionFgColor(): string | null
    {
        return this.m_section_fg_color;
    }

    /**
     * Check if the section has a background image
     */
    public hasSectionImage(): boolean
    {
        return this.m_has_image === 1;
    }

    /**
     * Make an Api request for get the background image
     */
    public getSectionImageUrl(): string | null
    {
        return `${API_PREFIX_MAIN}/home/section/${this.m_section_id}/image`;
    }
}
