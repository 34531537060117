import {JsonObject, JsonProperty} from "@axional/ax-object-mapper";

@JsonObject("HomeCardItem")
export class HomeCardItem {
    /**
     *
     * @private
     */
    @JsonProperty("item_name", String)
    private m_item_name: string = "";

    /**
     *
     * @private
     */
    @JsonProperty("item_icon", String, true)
    private m_item_icon: string | null = null;

    /**
     *
     * @private
     */
    @JsonProperty("item_bg_color", String, true)
    private m_item_bg_color: string | null = null;

    /**
     *
     * @private
     */
    @JsonProperty("item_fg_color", String, true)
    private m_item_fg_color: string | null = null;

    /**
     *
     * @private
     */
    @JsonProperty("link_url", String, true)
    private m_link_url: string | null = null;

    /**
     *
     * @private
     */
    @JsonProperty("news_id", Number, true)
    private m_news_id: number | null = null;

    public getItemTitle(): string
    {
        return this.m_item_name;
    }

    public getItemIcon(): string | null {
        return this.m_item_icon;
    }

    public getItemBgColor(): string | null {
        return this.m_item_bg_color;
    }

    public getItemFgColor(): string | null {
        return this.m_item_fg_color;
    }

    public getItemUrl(): string | null {
        return this.m_link_url;
    }

    public getItemNewsId(): number | null {
        return this.m_news_id;
    }
}
