import {JsonObject, JsonProperty} from "@axional/ax-object-mapper";
import {HomeCard} from "@/pages/home/impl/HomeCard";

/**
 * Wrapper class fro the Section Boxes
 * We use the boxes for to be able to group cards in the same column
 *
 * Example of an array of boxes : [
 *      {
 *          box_id: 2
 *          box_cols: 6
 *          box_order: 2
 *          cards: [{card_id: 6, card_order: 2, card_name: "",…}]
 *      },
 *      {
 *          box_id: 4
 *          box_cols: 6
 *          box_order: 4
 *          cards: [{card_id: 6, card_order: 2, card_name: "",…}]
 *      }
 * ]
 *
 */
@JsonObject("HomeBox")
export class HomeBox {
    /**
     * The box id
     * @private
     */
    @JsonProperty("box_id", Number)
    private m_box_id: number = 0;

    /**
     * The order of the box
     * @private
     */
    @JsonProperty("box_order", Number)
    private m_box_order: number = 0;

    /**
     * The box cols 0-12
     * @private
     */
    @JsonProperty("box_cols", Number)
    private m_box_cols: number = 0;

    /**
     *
     * @private
     */
    @JsonProperty("cards", [HomeCard] , true)
    private m_cards: HomeCard[] = []

    /**
     *
     */
    public getBoxOrder(): number
    {
        return this.m_box_order;
    }

    /**
     * Get the box cols used for responsive content
     * Accepted values: Cols (0-12)
     */
    public getBoxCols(): number
    {
        return this.m_box_cols;
    }

    /**
     * Get a sorted array of cards that contain this box
     *
     * The sections boxes are sorted in client side
     * because in the server side the select returns a multiset
     */
    public getCards(): HomeCard[]
    {
        // Source data is sorted data through sort ().
        // because array.sort() changes the array itself, vue triggers re-render again
        // ending up in an infinite update loop.
        // To solve this, we need to shallow copy the array into a new array.
        const cards = [...this.m_cards];
        return cards.sort((a,b) => a.getCardOrder() - b.getCardOrder());
    }
}
